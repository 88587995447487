/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.form-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 3em; }
  .form-wrap h3 {
    position: relative;
    z-index: 3;
    font-family: sectra, serif;
    font-size: 25px;
    width: 100%; }
  .form-wrap form {
    position: relative;
    z-index: 8;
    width: 100%; }
    .form-wrap form .input-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1em; }
    .form-wrap form input {
      padding: 1em;
      width: 47%;
      font-family: fabrique, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      color: #999999;
      border-color: #999999;
      border-width: 1px; }
    .form-wrap form textarea {
      width: 100%;
      min-height: 10em;
      font-family: fabrique, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      padding: 1em;
      color: #999999;
      border-color: #999999 !important;
      margin-bottom: 1em; }
    .form-wrap form button {
      display: block;
      font-family: fabrique, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      color: #001938;
      font-size: 18px;
      height: 2em;
      border: none;
      background-color: transparent; }
      .form-wrap form button:hover {
        color: #999999; }

@media screen and (max-width: 1100px) {
  .form-wrap {
    padding: 1.5em; }
    .form-wrap form {
      margin-top: 1em; } }
