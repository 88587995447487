/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.highlight {
  background: blue; }

#top {
  position: absolute;
  top: 0;
  left: 0; }

.our-team {
  width: 100vw;
  padding: 50px;
  min-height: 50vh;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center; }
  .our-team .inner-wrap {
    width: 100%; }
    .our-team .inner-wrap .our-team-tagline {
      max-width: 60%;
      margin-left: 20%; }
    .our-team .inner-wrap .team-members h3 {
      color: lightgrey; }
    .our-team .inner-wrap .active {
      color: #444444; }
    .our-team .inner-wrap h3:first-of-type {
      width: 100%;
      margin-bottom: 10px; }
    .our-team .inner-wrap .bio-section {
      width: 100%;
      position: relative;
      height: 300px;
      background-color: red; }
  .our-team .team-members {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-top: 50px; }

.col-1 {
  column-count: 2;
  margin-top: -150px; }
  .col-1 .description {
    margin-top: 200px;
    padding: 50px 0 50px 50px; }
  .col-1 .paragraph {
    padding: 0px 0 50px 50px; }

.col-2 {
  position: relative;
  z-index: 7;
  float: right;
  padding: 50px 0 50px 50px; }
  .col-2 img {
    max-width: 100%; }

@media screen and (max-width: 1000px) {
  .our-team {
    padding: 25px; }
    .our-team .inner-wrap .our-team-tagline {
      max-width: 100%;
      margin-left: 0%; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .our-team .inner-wrap {
    text-align: center; } }
