/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.dl-trans-wrap .icon {
  display: none; }

.icon {
  position: relative;
  height: 20px;
  width: 20px; }
  .icon .plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .icon .plus .vertical {
      height: 100%;
      width: 2px;
      background-color: #001938;
      position: absolute;
      left: calc(50% - 1px); }
    .icon .plus .horizontal {
      width: 100%;
      height: 2px;
      background-color: #001938; }
  .icon .minus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .icon .minus .horizontal {
      width: 100%;
      height: 2px;
      background-color: #001938; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .icon .plus .horizontal {
    position: absolute;
    top: 18px;
    left: -4px; }
  .icon .plus .vertical {
    position: absolute;
    left: 5px; } }
