/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.news-wrap {
  padding: 0 50px;
  margin-bottom: 100px; }
  .news-wrap .text-wrap {
    flex-direction: column; }
  .news-wrap .pos-rel {
    position: relative; }
    .news-wrap .pos-rel .anchor-span {
      position: absolute;
      top: -200px;
      width: 100%;
      height: 2px; }
  .news-wrap .news-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative; }
    .news-wrap .news-header .text-left {
      display: inline-block; }
      .news-wrap .news-header .text-left .index {
        font-size: 14px; }
    .news-wrap .news-header .text-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      max-width: 60%; }
      .news-wrap .news-header .text-right .year-wrap {
        display: inline;
        margin: 0; }
        .news-wrap .news-header .text-right .year-wrap h3 {
          display: inline;
          line-height: 40px; }
        .news-wrap .news-header .text-right .year-wrap p {
          display: inline;
          font-family: sectra, serif; }
      .news-wrap .news-header .text-right .active {
        color: #999999; }
      .news-wrap .news-header .text-right h3 {
        line-height: 100px;
        display: inline-block;
        margin: 0.5em;
        font-family: sectra, serif; }
  .news-wrap .item-wrap {
    margin: 50px 0; }
  .news-wrap .entries {
    width: 60%;
    margin-left: 20%; }

@media screen and (max-width: 1250px) {
  .news-wrap {
    width: 100vw !important; } }

@media screen and (max-width: 1000px) {
  .news-wrap .entries {
    width: 100%;
    margin-left: 0; } }

@media screen and (max-width: 675px) {
  .news-wrap {
    width: 100vw !important;
    padding: 0 25px; }
    .news-wrap .news-header {
      align-items: flex-start;
      flex-direction: column; }
      .news-wrap .news-header .text-right {
        width: 100%;
        max-width: 100%;
        justify-content: flex-start; }
        .news-wrap .news-header .text-right .year-wrap {
          margin-right: 0.5em; }
        .news-wrap .news-header .text-right h3 {
          margin-left: 0; }
    .news-wrap .year-wrap {
      margin-top: 50px; } }
