/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.wrapper {
  display: inline-block;
  width: auto; }

.label {
  display: inline-block;
  width: 1em; }

.homepage-caption {
  position: fixed;
  z-index: 8;
  bottom: 50px;
  right: 50px; }
  .homepage-caption p {
    color: lightgray;
    font-family: sectra-italic, serif;
    font-size: 14px; }

.contents,
.contents .inner {
  display: inline-flex; }

.contents {
  white-space: nowrap;
  margin-left: -1em;
  padding-left: 1em; }

.contents .inner {
  background: #fffade;
  overflow: hidden;
  opacity: 0; }

.innerAnimate {
  animation: 1.8s ease-out 0s 1 widthAnim;
  opacity: 1 !important;
  transition: opacity 1s; }

@keyframes widthAnim {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.home-wrap {
  width: 100vw;
  position: relative;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  padding: 50px; }
  .home-wrap .tagline {
    width: 75%;
    position: relative;
    z-index: 3; }
    .home-wrap .tagline h1 {
      font-size: 72px;
      color: white; }
  .home-wrap .taglineText {
    opacity: 0; }
  .home-wrap .taglineAnimate {
    opacity: 1;
    transition: 1s opacity; }
  .home-wrap .bg-fade {
    background-color: #444444;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }
  .home-wrap .background-carousel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; }
  .home-wrap .slideshow {
    overflow: hidden;
    max-height: 100%;
    height: 100%; }
    .home-wrap .slideshow img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute; }

@media screen and (max-width: 1100px) {
  .home-wrap {
    padding: 25px;
    padding-top: 150px;
    align-items: flex-start; }
    .home-wrap .tagline {
      width: 100%; }
      .home-wrap .tagline h1 {
        font-size: 80px;
        line-height: 105px; } }

@media screen and (max-width: 675px) {
  .home-wrap {
    padding: 25px;
    padding-top: 150px;
    align-items: flex-start;
    overflow: hidden; }
    .home-wrap .tagline {
      width: 100%; }
      .home-wrap .tagline h1 {
        font-size: 45px;
        line-height: 65px; } }
