/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.portfolio-wrap {
  padding: 50px;
  overflow: hidden; }
  .portfolio-wrap bold {
    font-family: fabrique, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }
  .portfolio-wrap .bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease-in-out;
    background-size: cover; }
  .portfolio-wrap .grey {
    color: #999999; }
  .portfolio-wrap .portfolio-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative; }
    .portfolio-wrap .portfolio-header .text-left {
      display: inline-block; }
      .portfolio-wrap .portfolio-header .text-left .index {
        font-size: 14px; }
    .portfolio-wrap .portfolio-header .text-right {
      display: inline-block; }
      .portfolio-wrap .portfolio-header .text-right .active {
        color: #444444; }
      .portfolio-wrap .portfolio-header .text-right span {
        cursor: pointer; }
      .portfolio-wrap .portfolio-header .text-right h3 {
        line-height: 50px;
        color: #999999; }
        .portfolio-wrap .portfolio-header .text-right h3 span:hover {
          color: #444444;
          transition: 0.25s all; }
  .portfolio-wrap .portfolio-grid {
    position: relative;
    display: grid;
    width: 100%;
    margin-top: 50px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: minmax(200px, 300px);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    transition: 0.5s; }
  .portfolio-wrap .testimonial-wrap {
    width: 100%;
    position: relative;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

@media screen and (max-width: 1100px) {
  .portfolio-wrap {
    padding: 25px; }
    .portfolio-wrap .portfolio-header {
      flex-direction: column;
      align-items: flex-start; }
      .portfolio-wrap .portfolio-header .text-left h1,
      .portfolio-wrap .portfolio-header .text-right h1 {
        line-height: 70px; }
      .portfolio-wrap .portfolio-header .text-left h3,
      .portfolio-wrap .portfolio-header .text-right h3 {
        line-height: 40px; }
    .portfolio-wrap .testimonial-wrap {
      margin-top: 50px; }
      .portfolio-wrap .testimonial-wrap h3 {
        margin-top: 25px;
        text-align: center; }
      .portfolio-wrap .testimonial-wrap .testimonial {
        width: 100%; } }
