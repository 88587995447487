/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.page-header {
  width: 100%;
  background-color: #ff9e23;
  height: 200px;
  padding-left: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .page-header .header-info {
    color: white; }
    .page-header .header-info .index {
      font-size: 14px; }
    .page-header .header-info .title h1 {
      color: white; }

@media screen and (max-width: 1000px) {
  .page-header {
    padding-left: 25px;
    height: 150px; }
    .page-header .header-info .title h1 {
      line-height: 65px; } }
