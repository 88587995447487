/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.portfolio-item {
  width: 100%;
  height: 100%;
  z-index: 3;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .portfolio-item a {
    text-align: center;
    position: relative;
    z-index: 100; }
    .portfolio-item a img {
      max-width: 80%;
      margin-right: 10%;
      max-height: 100%;
      margin-left: 10%; }
  .portfolio-item .darken {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: 0.25s opacity; }
  .portfolio-item:hover .darken {
    opacity: 0.3;
    transition: 0.25s opacity; }
