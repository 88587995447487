/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
/*.................................................................................*/
/*$break-large-desktop: 2500px;*/
/*$break-infinite: 500000px;*/
/*....................................SCSS MIXINS..................................*/
.view-investments {
  margin-top: 50px; }
  .view-investments h3 {
    background-color: #ecf8ff;
    padding: 10px;
    display: inline; }

.direct-lending .list-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0;
  margin-bottom: 150px;
  margin-left: 100px;
  margin-right: 100px; }
  .direct-lending .list-wrap .list-item {
    margin-top: 0;
    width: 45%; }

.transaction-wrap {
  width: calc(100% - 50px);
  margin-left: 50px;
  margin-bottom: 150px; }
  .transaction-wrap .section-title {
    margin-bottom: 20px; }

@media screen and (max-width: 1000px) {
  .direct-lending .list-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px;
    margin-bottom: 50px; }
    .direct-lending .list-wrap .list-item {
      width: 100%; }
  .direct-lending .transaction-wrap {
    margin-left: 25px; } }

@media screen and (max-width: 775px) {
  .direct-lending .list-wrap .list-item {
    max-width: 100%;
    margin-top: 0; } }
