/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.no-flick {
  -webkit-transform: translate3d(0, 0, 0); }

svg {
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden; }
  svg .background {
    fill: #001938; }
  svg .sidebar-left {
    transform: translateY(-100px);
    -moz-transform: translateY(-100);
    -ms-transform: translateY(-100);
    -o-transform: translateY(-100);
    -webkit-transform: translateY(-100);
    transform: translateY(-100);
    -moz-transition: transform 2s transform ease-out;
    -o-transition: transform 2s transform ease-out;
    -webkit-transition: transform 2s transform ease-out;
    transition: transform 2s transform ease-out;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d; }
  svg .sidebar-right {
    transform: translateY(100px);
    -moz-transform: translateY(100);
    -ms-transform: translateY(100);
    -o-transform: translateY(100);
    -webkit-transform: translateY(100);
    transform: translateY(100);
    -moz-transition: transform 2s transform ease-out;
    -o-transition: transform 2s transform ease-out;
    -webkit-transition: transform 2s transform ease-out;
    transition: transform 2s transform ease-out;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d; }
  svg .center {
    transform: translateX(-100px);
    -moz-transform: translateX(-100);
    -ms-transform: translateX(-100);
    -o-transform: translateX(-100);
    -webkit-transform: translateX(-100);
    transform: translateX(-100);
    -moz-transition: transform 2s transform ease-out;
    -o-transition: transform 2s transform ease-out;
    -webkit-transition: transform 2s transform ease-out;
    transition: transform 2s transform ease-out;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d; }

svg.animate .sidebar-left {
  transform: translateY(0.3px);
  transition: all 2s, fill 0.2s; }

svg.animate .sidebar-right {
  transform: translateY(-0.3px);
  transition: all 2s, fill 0.2s; }

svg.animate .center {
  transform: translateX(-1px);
  transition: all 2s, fill 0.2s; }

@media screen and (max-width: 675px) {
  svg {
    margin-left: -10px;
    max-width: 45px; } }
