.fade-leave {
  opacity: 1; }

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in; }

.fade-enter {
  opacity: 0; }

.fade-enter.fade-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity 0.4s ease-in 0.6s; }

.fade-height {
  transition: height 0s ease-in-out; }
