/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.team-member {
  width: 100%;
  margin: 50px 0; }
  .team-member .member-inner-wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    min-height: 300px; }
    .team-member .member-inner-wrap .profile-pic {
      max-width: 50%;
      min-height: 1px; }
  .team-member img {
    max-width: 100%;
    display: inline-block; }
  .team-member .bio {
    display: inline-block;
    position: relative;
    margin-top: 25px;
    left: 0; }
  .team-member .text-wrap {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;
    max-width: 60%;
    padding: 50px;
    padding-top: 0; }
    .team-member .text-wrap .info-wrap {
      text-align: left; }
      .team-member .text-wrap .info-wrap .name p {
        font-size: 18px; }
      .team-member .text-wrap .info-wrap .jobtitle p {
        font-size: 14px; }
    .team-member .text-wrap .icon {
      position: absolute;
      right: 10px;
      top: calc(50% - 10px);
      height: 20px;
      width: 20px; }
      .team-member .text-wrap .icon .plus {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%; }
        .team-member .text-wrap .icon .plus .vertical {
          height: 100%;
          width: 2px;
          background-color: #001938;
          position: absolute;
          left: calc(50% - 1px); }
        .team-member .text-wrap .icon .plus .horizontal {
          width: 100%;
          height: 2px;
          background-color: #001938; }
      .team-member .text-wrap .icon .minus {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        display: none; }
        .team-member .text-wrap .icon .minus .horizontal {
          width: 100%;
          height: 2px;
          background-color: #001938; }
    .team-member .text-wrap .carrot {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #fffade;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 20px);
      display: none; }

@media screen and (max-width: 1000px) {
  .team-member .member-inner-wrap {
    flex-direction: column; }
    .team-member .member-inner-wrap .profile-pic {
      width: 100%;
      max-width: 100%; }
      .team-member .member-inner-wrap .profile-pic img {
        width: 100%; }
    .team-member .member-inner-wrap .text-wrap {
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-top: 25px; } }
