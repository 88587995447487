/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.portfolio-detail-wrap {
  padding-left: 50px;
  position: relative;
  min-height: 100vh; }
  .portfolio-detail-wrap .separator {
    width: 100%;
    height: 5px;
    background-color: #faf8f3;
    margin-bottom: 20px; }
  .portfolio-detail-wrap .context {
    position: relative; }
    .portfolio-detail-wrap .context p {
      font-family: sectra, serif;
      font-size: 14px; }
  .portfolio-detail-wrap .inner-wrap {
    margin-top: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .portfolio-detail-wrap .inner-wrap .left {
      width: 49%;
      padding-right: 50px; }
      .portfolio-detail-wrap .inner-wrap .left img {
        max-height: 250px;
        margin-bottom: 2em; }
      .portfolio-detail-wrap .inner-wrap .left .description {
        font-family: sectra, serif; }
    .portfolio-detail-wrap .inner-wrap .right {
      width: 50%; }
      .portfolio-detail-wrap .inner-wrap .right img {
        max-width: 100%; }

@media screen and (max-width: 1100px) {
  .portfolio-detail-wrap {
    padding: 25px; }
    .portfolio-detail-wrap .inner-wrap {
      width: 100%;
      flex-direction: column; }
      .portfolio-detail-wrap .inner-wrap .left {
        width: 100%;
        padding: 0;
        margin-bottom: 50px; }
        .portfolio-detail-wrap .inner-wrap .left img {
          margin: auto;
          display: block; }
      .portfolio-detail-wrap .inner-wrap .right {
        width: 100%; } }
