/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.news-item {
  width: 100%;
  margin-top: 50px;
  z-index: 3;
  display: flex; }
  .news-item .text-wrap {
    width: 100%; }
    .news-item .text-wrap .date {
      font-size: 12px;
      color: #999999; }
    .news-item .text-wrap .title {
      margin-top: 0 5em;
      font-family: sectra, serif;
      font-size: 28px;
      line-height: 33px; }
    .news-item .text-wrap .excerpt {
      font-family: sectra, serif;
      margin: 1em 0; }
    .news-item .text-wrap .read-more {
      margin-top: 1em; }

@media screen and (max-width: 675px) {
  .news-item {
    flex-direction: column; }
    .news-item .image-wrap {
      width: 100%; }
    .news-item .text-wrap {
      width: 100%;
      margin-top: 1em; } }
