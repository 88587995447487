/*....................................SCSS VARIABLES..................................*/
@font-face {
  font-family: fabrique;
  src: url(./../../assets/fonts/lafabrique-heavy.ttf); }

@font-face {
  font-family: sectra;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book.ttf);
  font-weight: 400; }

@font-face {
  font-family: sectra-italic;
  src: url(./../../assets/fonts/GT-Sectra-Fine-Book-Italic.ttf);
  font-weight: 400; }

/*....................................................................................*/
.contact-wrap {
  padding-left: 50px;
  padding-top: 50px;
  overflow: hidden;
  padding-bottom: 100px; }
  .contact-wrap .flex-wrap {
    display: flex;
    justify-content: space-between;
    position: relative; }
  .contact-wrap .left-col {
    width: 50%;
    position: relative;
    padding-right: 3em; }
    .contact-wrap .left-col .tagline {
      font-family: sectra, serif; }
    .contact-wrap .left-col .address {
      margin: 50px 0; }
  .contact-wrap .right-col {
    width: 50%;
    position: relative; }
  .contact-wrap .map {
    margin-top: 100px;
    width: 100%;
    position: relative;
    min-height: 300px; }

@media screen and (max-width: 1100px) {
  .contact-wrap {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 50px;
    width: 100vw; }
    .contact-wrap .flex-wrap {
      flex-direction: column;
      width: 100%; }
      .contact-wrap .flex-wrap .left-col {
        width: 100%; }
      .contact-wrap .flex-wrap .right-col {
        width: 100%;
        margin-top: 50px; }
    .contact-wrap .map {
      margin-top: 50px; } }
